import React from 'react'
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'

import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: theme.palette.common.white,
	},
}))

export default function BackdropLoader(props) {
	const classes = useStyles()

	return (
		<div>
			<Backdrop className={classes.backdrop} open={props.open}>
				<CircularProgress color='inherit' />
			</Backdrop>
		</div>
	)
}

BackdropLoader.propTypes = {
	open: PropTypes.bool.isRequired,
}
