export default {
  POST_PAYMENT: {
    START: 'payment.post_payment:start',
    SUCCESS: 'payment.post_payment:success',
    FAIL: 'payment.post_payment:fail',
  },
  GET_PAYMENT: {
    START: 'payment.get_payment:start',
    SUCCESS: 'payment.get_payment:success',
    FAIL: 'payment.get_payment:fail',
  },
  GET_PAYMENT_BY_ID: {
    START: 'payment.get_payment_by_id:start',
    SUCCESS: 'payment.get_payment_by_id:success',
    FAIL: 'payment.get_payment_by_id:fail',
  },
  REMOVE_PAYMENT: {
    START: 'payment.remove_payment:start',
    SUCCESS: 'payment.remove_payment:success',
    FAIL: 'payment.remove_payment:fail',
  },
  PUT_PAYMENT: {
    START: 'payment.put_payment:start',
    SUCCESS: 'payment.put_payment:success',
    FAIL: 'payment.put_payment:fail',
  },
  GET_COMMENTS: {
    START: 'payment.get_comments:start',
    SUCCESS: 'payment.get_comments:success',
    FAIL: 'payment.get_comments:fail',
  },
  POST_COMMENTS: {
    START: 'payment.post_comments:start',
    SUCCESS: 'payment.post_comments:success',
    FAIL: 'payment.post_comments:fail',
  },
  PAID_USERS: {
    START: 'payment.paid_users:start',
    SUCCESS: 'payment.paid_users:success',
    FAIL: 'payment.paid_users:fail',
  },
  UNPAID_USERS: {
    START: 'payment.unpaid_users:start',
    SUCCESS: 'payment.unpaid_users:success',
    FAIL: 'payment.unpaid_users:fail',
  },
  PAYMENT_BY_ID: {
    START: 'payment.payment_by_id:start',
    SUCCESS: 'payment.payment_by_id:success',
    FAIL: 'payment.payment_by_id:fail',
  },
  POST_PAY: {
    START: 'payment.post_pay:start',
    SUCCESS: 'payment.post_pay:success',
    FAIL: 'payment.post_pay:fail',
  },
  CREATE_ORDER: {
    START: 'payment.create_order:start',
    SUCCESS: 'payment.create_order:success',
    FAIL: 'payment.create_order:fail',
  },
}
