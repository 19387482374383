export default {
  POST_REPORT: {
    START: 'report.post_report:start',
    SUCCESS: 'report.post_report:success',
    FAIL: 'report.post_report:fail',
  },
  PUT_ASSIGNMENT: {
    START: 'report.put_assignment:start',
    SUCCESS: 'report.put_assignment:success',
    FAIL: 'report.put_assignment:fail',
  },
  PUT_NEWS: {
    START: 'report.put_news:start',
    SUCCESS: 'report.put_news:success',
    FAIL: 'report.put_news:fail',
  },
  PUT_ATTENDANCE: {
    START: 'report.put_attendance:start',
    SUCCESS: 'report.put_attendance:success',
    FAIL: 'report.put_attendance:fail',
  },
  PUT_PAYMENT: {
    START: 'report.put_payment:start',
    SUCCESS: 'report.put_payment:success',
    FAIL: 'report.put_payment:fail',
  },
  GET_REPORT: {
    START: 'report.get_report:start',
    SUCCESS: 'report.get_report:success',
    FAIL: 'report.get_report:fail',
  },
  GET_REPORT_BY_ID: {
    START: 'report.get_report_by_id:start',
    SUCCESS: 'report.get_report_by_id:success',
    FAIL: 'report.get_report_by_id:fail',
  },
  DELETE_REPORT: {
    START: 'report.delete_report:start',
    SUCCESS: 'report.delete_report:success',
    FAIL: 'report.delete_report:fail',
  },
  DOWNLOAD_REPORT: {
    START: 'report.download_report:start',
    SUCCESS: 'report.download_report:success',
    FAIL: 'report.download_report:fail',
  },
}
