export const Lang = {
	HOME: {
		HOME: 'Home',
		TASKS: 'TASKS',
		HOMEWORKS: 'HOMEWORKS',
		ANNOUNCEMENTS: 'ANNOUNCEMENTS',
		EVENTS_ANNOUNCEMENTS: 'Events/Announcements',
		HOMEWORK: 'Homework',
		CHATS: 'Chats',
		NEW_GROUP: 'New Group',
	},
	NOTIFICATIONS: 'Notifications',
	ASSIGNMENT: 'Assignment',
	ATTENDANCE: 'Attendance',
	EVENTS_ANNOUNCEMENTS: 'Events & Announcements',
	PLANNING: 'Planning',
	TRANSPORT: 'Transport',
	GALLERY: 'Gallery',
	PAYMENTS: 'Payments',
	DATE_SHEET: 'Date Sheet',
	LEAVE: 'Leave',
	FAQ: 'FAQ',
	SUPPORT: 'Support',
	WEEKLY_TIME_TABLE: 'Weekly Time Table',
	DIARY: 'Diary',
	STUDENT_REPORT_CARD: 'Student Report Card',
	STUDENT_REPORTS: 'Student Reports',
	BULK_UPLOAD: 'Bulk Upload',
}
