export const paths = {
  VIEW: "/view",
  DETAILS: "/details",
  DATE_SHEET: "/dateSheet",
  HOME: "/home",
  NOTIFICATIONS: "/notifications",
  CREATE_NOTIFICATIONS: "/create-notification",
  ANNOUNCEMENT: "/announcement",
  NEWS: "/news",
  ASSIGNMENT: "/assignment",
  HOMEWORK: "/homework",
  CREATE_HOMEWORK: "/create-homework",
  ATTENDANCE: "/attendance",
  ATTENDANCE_UPLOAD: "/attendance/upload",
  EVENTS: "/events",
  CREATE_ANNOUNCEMENT: "/create-announcement",
  PLANNING: "/planning",
  CREATE_PLANNING: "/create-planning",
  TRANSPORT: "/transport",
  GALLERY: "/gallery",
  GALLERY_UPLOAD: "/gallery/upload",
  GALLERY_MOVE: "/gallery/move",
  PAYMENTS: "/payments",
  LEAVE: "/leave/",
  CREATE_LEAVE: "/leave/create/",
  FAQ: "/faq",
  CREATE_FAQ: "/faq/create",
  FAQ_EDIT: "/faq/edit",
  PROFILE: "/profile",
  SUPPORT: "/support",
  CREATE_SUPPORT: "/support/create",
  CHAT: "/chat",
  REPORT: "/report",
  REPORT_CARD: "/report-card",
  REPORT_UPLOAD: "/report-upload",
  UPDATE_GROUP: "/updategroup",
  WEEKLY_TIME_TABLE: "/weekly-time-table",
  DIARY: "/diary",
  SETTINGS: "/settings"
};
