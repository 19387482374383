export default {
  GET_ATTENDENCE: {
    START: "attendence.get_attendence:start",
    SUCCESS: "attendence.get_attendence:success",
    FAIL: "attendence.get_attendence:fail",
  },
  GET_CLASSES: {
    START: "attendence.get_classes:start",
    SUCCESS: "attendence.get_classes:success",
    FAIL: "attendence.get_classes:fail",
  },
  GET_SINGLE_CLASS: {
    START: "attendence.get_single_class:start",
    SUCCESS: "attendence.get_single_class:success",
    FAIL: "attendence.get_single_class:fail",
  },
  GET_STUDENTS: {
    START: "attendence.get_students:start",
    SUCCESS: "attendence.get_students:success",
    FAIL: "attendence.get_students:fail",
  },
  GET_SUBJECTS: {
    START: "attendence.get_subjects:start",
    SUCCESS: "attendence.get_subjects:success",
    FAIL: "attendence.get_subjects:fail",
  },
  POST_ATTENDANCE: {
    START: "attendence.post_attendance:start",
    SUCCESS: "attendence.post_attendance:success",
    FAIL: "attendence.post_attendance:fail",
  },
  UPDATE_ATTENDANCE: {
    START: "attendence.update_attendance:start",
    SUCCESS: "attendence.update_attendance:success",
    FAIL: "attendence.update_attendance:fail",
  },
  IMPORT_ATTENDANCE: {
    START: "attendence.import_attendance:start",
    SUCCESS: "attendence.import_attendance:success",
    FAIL: "attendence.import_attendance:fail",
  },
  EXPORT_ATTENDANCE: {
    START: "attendence.export_attendance:start",
    SUCCESS: "attendence.export_attendance:success",
    FAIL: "attendence.export_attendance:fail",
  },
  GET_WEEKLY_TIMETABLE_SUBJECTS: {
    START: "attendence.get_weekly_timetable_subjects:start",
    SUCCESS: "attendence.get_weekly_timetable_subjects:success",
    FAIL: "attendence.get_weekly_timetable_subjects:fail",
  },
  POST_ADD_SUBJECTS_OR_TIME: {
    START: "attendence.post_add_subjects_or_time:start",
    SUCCESS: "attendence.post_add_subjects_or_time:success",
    FAIL: "attendence.post_add_subjects_or_time:fail",
  },
  GET_WEEK_FILTER_USING_ALL: {
    START: "attendence.get_week_filter_using_all:start",
    SUCCESS: "attendence.get_week_filter_using_all:success",
    FAIL: "attendence.get_week_filter_using_all:fail",
  },
  PUBLISH_WEEKLY_TIME_TABLE: {
    START: "attendence.publish_weekly_time_table:start",
    SUCCESS: "attendence.publish_weekly_time_table:success",
    FAIL: "attendence.publish_weekly_time_table:fail",
  },
  ADD_DISH_IN_DISHES: {
    START: "attendence.add_dish_in_dishes:start",
    SUCCESS: "attendence.add_dish_in_dishes:success",
    FAIL: "attendence.add_dish_in_dishes:fail",
  },
  SHOW_DISH_LIST_IN_DISHES: {
    START: "attendence.show_dish_list_in_dishes:start",
    SUCCESS: "attendence.show_dish_list_in_dishes:success",
    FAIL: "attendence.show_dish_list_in_dishes:fail",
  },
  DELETE_ROW_WEEKLY_TIME_TABLE: {
    START: "attendence.delete_row_weekly_time_table:start",
    SUCCESS: "attendence.delete_row_weekly_time_table:success",
    FAIL: "attendence.delete_row_weekly_time_table:fail",
  },
  DELETE_DISH_IN_DISHES: {
    START: "attendence.delete_dish_in_dishes:start",
    SUCCESS: "attendence.delete_dish_in_dishes:success",
    FAIL: "attendence.delete_dish_in_dishes:fail",
  },
  UPDATE_DISH_IN_DISHES: {
    START: "attendence.update_dish_in_dishes:start",
    SUCCESS: "attendence.update_dish_in_dishes:success",
    FAIL: "attendence.update_dish_in_dishes:fail",
  },
  LUNCH_MENU_SEARCH: {
    START: "attendence.lunch_menu_search:start",
    SUCCESS: "attendence.lunch_menu_search:success",
    FAIL: "attendence.lunch_menu_search:fail",
  },
  LUNCH_MENU_GET_BY_DISH: {
    START: "attendence.lunch_menu_get_by_dish:start",
    SUCCESS: "attendence.lunch_menu_get_by_dish:success",
    FAIL: "attendence.lunch_menu_get_by_dish:fail",
  },
  LUNCH_MENU_GET_BY_WEEK: {
    START: "attendence.lunch_menu_get_by_week:start",
    SUCCESS: "attendence.lunch_menu_get_by_week:success",
    FAIL: "attendence.lunch_menu_get_by_week:fail",
  },
  LUNCH_MENU_PUBLISH_NOW: {
    START: "attendence.lunch_menu_publish_now:start",
    SUCCESS: "attendence.lunch_menu_publish_now:success",
    FAIL: "attendence.lunch_menu_publish_now:fail",
  },
  GET_LUNCH_MENU_ID: {
    START: "attendence.get_lunch_menu_id:start",
    SUCCESS: "attendence.get_lunch_menu_id:success",
    FAIL: "attendence.get_lunch_menu_id:fail",
  },
  LUNCH_MENU_ALL: {
    START: "attendence.lunch_menu_all:start",
    SUCCESS: "attendence.lunch_menu_all:success",
    FAIL: "attendence.lunch_menu_all:fail",
  },
  MENU_DISH_DETAILS: {
    START: "attendence.menu_dish_details:start",
    SUCCESS: "attendence.menu_dish_details:success",
    FAIL: "attendence.menu_dish_details:fail",
  },
  DELETE_MENU_IN_LIST: {
    START: "attendence.delete_menu_in_list:start",
    SUCCESS: "attendence.delete_menu_in_list:success",
    FAIL: "attendence.delete_menu_in_list:fail",
  },
  REMOVE_LUNCH_IMAGE: {
    START: "attendence.remove_lunch_image:start",
    SUCCESS: "attendence.remove_lunch_image:success",
    FAIL: "attendence.remove_lunch_image:fail",
  },
  HOLIDAY_ALL: {
    START: "attendence.holiday_all:start",
    SUCCESS: "attendence.holiday_all:success",
    FAIL: "attendence.holiday_all:fail",
  },
  GET_BY_MONTH: {
    START: "attendence.get_by_month:start",
    SUCCESS: "attendence.get_by_month:success",
    FAIL: "attendence.get_by_month:fail",
  },
  HOLIDAY_BY_MONTH: {
    START: "attendence.holiday_by_month:start",
    SUCCESS: "attendence.holiday_by_month:success",
    FAIL: "attendence.holiday_by_month:fail",
  },
  MISCELLANEOUS: {
    START: "attendence.miscellaneous:start",
    SUCCESS: "attendence.miscellaneous:success",
    FAIL: "attendence.miscellaneous:fail",
  },
  UPDATE_MISCELLANEOUS: {
    START: "attendence.update_miscellaneous:start",
    SUCCESS: "attendence.update_miscellaneous:success",
    FAIL: "attendence.update_miscellaneous:fail",
  },
  GET_MISCELLANEOUS: {
    START: "attendence.get_miscellaneous:start",
    SUCCESS: "attendence.get_miscellaneous:success",
    FAIL: "attendence.get_miscellaneous:fail",
  },
  GET_ALL_MISCELLANEOUS: {
    START: "attendence.get_all_miscellaneous:start",
    SUCCESS: "attendence.get_all_miscellaneous:success",
    FAIL: "attendence.get_all_miscellaneous:fail",
  },
  POST_DIARY_MULTIPLE: {
    START: "attendence.post_diary_multiple:start",
    SUCCESS: "attendence.post_diary_multiple:success",
    FAIL: "attendence.post_diary_multiple:fail",
  },
  PUT_DIARY_MULTIPLE: {
    START: "attendence.put_diary_multiple:start",
    SUCCESS: "attendence.put_diary_multiple:success",
    FAIL: "attendence.put_diary_multiple:fail",
  },
  GET_DIARY_MULTIPLE: {
    START: "attendence.get_diary_multiple:start",
    SUCCESS: "attendence.get_diary_multiple:success",
    FAIL: "attendence.get_diary_multiple:fail",
  },
  DIARY_MULTIPLE_SEEN_UNSEEN: {
    START: "attendence.diary_multiple_seen_unseen:start",
    SUCCESS: "attendence.diary_multiple_seen_unseen:success",
    FAIL: "attendence.diary_multiple_seen_unseen:fail",
  },
  DELETE_DIARY_MULTIPLE: {
    START: "attendence.delete_diary_multiple:start",
    SUCCESS: "attendence.delete_diary_multiple:success",
    FAIL: "attendence.delete_diary_multiple:fail",
  },
   SCHOOL_GRADE: {
    START: "attendence.school_grade:start",
    SUCCESS: "attendence.school_grade:success",
    FAIL: "attendence.school_grade:fail",
  },
  INDIVIDUAL_DIARY_POST: {
    START: "attendence.individual_diary_post:start",
    SUCCESS: "attendence.individual_diary_post:success",
    FAIL: "attendence.individual_diary_post:fail",
  },
  INDIVIDUAL_DIARY_PUT: {
    START: "attendence.individual_diary_put:start",
    SUCCESS: "attendence.individual_diary_put:success",
    FAIL: "attendence.individual_diary_put:fail",
  },
  INDIVIDUAL_DIARY_PUT_FOR_PARENT: {
    START: "attendence.individual_diary_put_for_parent:start",
    SUCCESS: "attendence.individual_diary_put_for_parent:success",
    FAIL: "attendence.individual_diary_put_for_parent:fail",
  },
  BY_ME_TEACHER_DIARY: {
    START: "attendence.by_me_teacher_diary:start",
    SUCCESS: "attendence.by_me_teacher_diary:success",
    FAIL: "attendence.by_me_teacher_diary:fail",
  },
  FOR_ME_TEACHER_DIARY: {
    START: "attendence.for_me_teacher_diary:start",
    SUCCESS: "attendence.for_me_teacher_diary:success",
    FAIL: "attendence.for_me_teacher_diary:fail",
  },
  GET_DETAILS_BY_ID: {
    START: "attendence.get_details_by_id:start",
    SUCCESS: "attendence.get_details_by_id:success",
    FAIL: "attendence.get_details_by_id:fail",
  },
  CLASS_STUDENT_LIST: {
    START: "attendence.class_student_list:start",
    SUCCESS: "attendence.class_student_list:success",
    FAIL: "attendence.class_student_list:fail",
  },
  GET_LIBRARY_INFO: {
    START: "attendence.get_library_info:start",
    SUCCESS: "attendence.get_library_info:success",
    FAIL: "attendence.get_library_info:fail",
  },
  GET_LIBRARY_INFO_BY_ID: {
    START: "attendence.get_library_info_by_id:start",
    SUCCESS: "attendence.get_library_info_by_id:success",
    FAIL: "attendence.get_library_info_by_id:fail",
  },
  POST_LIBRARY: {
    START: "attendence.post_library:start",
    SUCCESS: "attendence.post_library:success",
    FAIL: "attendence.post_library:fail",
  },
  PUT_LIBRARY: {
    START: "attendence.put_library:start",
    SUCCESS: "attendence.put_library:success",
    FAIL: "attendence.put_library:fail",
  },
  PUT_RETURN_LIBRARY: {
    START: "attendence.put_return_library:start",
    SUCCESS: "attendence.put_return_library:success",
    FAIL: "attendence.put_return_library:fail",
  },
  STUDENT_SIDE_DATA: {
    START: "attendence.student_side_data:start",
    SUCCESS: "attendence.student_side_data:success",
    FAIL: "attendence.student_side_data:fail",
  },
  SEARCH_BOOK: {
    START: "attendence.search_book:start",
    SUCCESS: "attendence.search_book:success",
    FAIL: "attendence.search_book:fail",
  },
  PUT_ACKNOWLEDGEMENT: {
    START: "attendence.put_acknowledgement:start",
    SUCCESS: "attendence.put_acknowledgement:success",
    FAIL: "attendence.put_acknowledgement:fail",
  },
  DIARY_PROFILE: {
    START: "attendence.diary_profile:start",
    SUCCESS: "attendence.diary_profile:success",
    FAIL: "attendence.diary_profile:fail",
  },
};
