export default {
	GET_SUPPORTS: {
		START: 'supports.get_supports:start',
		SUCCESS: 'supports.get_supports:success',
		FAIL: 'supports.get_supports:fail',
	},
	GET_CATEGORY: {
		START: 'supports.get_category:start',
		SUCCESS: 'supports.get_category:success',
		FAIL: 'supports.get_category:fail',
	},
	GET_SINGLE_CATEGORY: {
		START: 'supports.get_single_category:start',
		SUCCESS: 'supports.get_single_category:success',
		FAIL: 'supports.get_single_category:fail',
	},
	POST_SUPPORTS: {
		START: 'supports.post_category:start',
		SUCCESS: 'supports.post_category:success',
		FAIL: 'supports.post_category:fail',
	},
	GET_SINGLE_SUPPORT: {
		START: 'supports.get_single_support:start',
		SUCCESS: 'supports.get_single_support:success',
		FAIL: 'supports.get_single_support:fail',
	},
	UPDATE_SUPPORT: {
		START: 'supports.update_support:start',
		SUCCESS: 'supports.update_support:success',
		FAIL: 'supports.update_support:fail',
	},
	POST_COMMENT: {
		START: 'supports.post_comment:start',
		SUCCESS: 'supports.post_comment:success',
		FAIL: 'supports.post_comment:fail',
	},
	GET_SUPPORT_HISTORY: {
		START: 'supports.get_support_history:start',
		SUCCESS: 'supports.get_support_history:success',
		FAIL: 'supports.get_support_history:fail',
	},
}
