export const colors = {
    tint: '#7B72AF',
    primary: '#FBFAFF',
    danger: '#FF2623',
    red: '#D92424',
    lightBlack: '#1C1C1E',
    lightBlack2: '#2C2C2E',
    border: '#D1D1D6',
    border2: '#C7C7CC',
    darkGrey: '#636366',
    lightGrey: '#AEAEB2',
    grey: '#48484A',
    background: '#F4F4F4',
    tintLight: '#F2F0FA',
    tintLight2: '#F1F1F7',
    tintLight3: '#E7E7ED',
    backgroundGrey: '#E8E8E8',
    black: '#000',
    success: '#79C62B',
    green: '#41BE13',
    green2: '#40BD13',
    blue: '#003D8C',
    white: '#fff',
    borderDarkGrey: '#7E8989',
    textGrey: '#8E8E93',
    inputGrey: '#E6E6E6',
    placeholderColor: '#C9C9C9',
    linkText: '#52A9F6',
  };

  export const fonts = {
    avenirMedium: 'Avenir Medium',
    avenirBook: 'Avenir Book',
    avenirHeavy: 'Avenir Heavy',
    avenirLight: 'Avenir Light',
    avenirMediumOblique: 'Avenir MediumOblique',
    avenirBlack: 'Avenir Black',
  };