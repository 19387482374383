export const attendancesEndpoint = '/attendances'
export const classesEndpoint = '/classes'
export const studentEndpoint = '/getUsers/student'
export const subjectsEndpoint = '/subjects'
export const supportsEndpoint = '/supports'
export const categoryEndpoint = '/category'
export const supportCommentEndpoint = '/supportComments'
export const supportsHistoryEndpoint = '/supportHistories'
export const importAttendanceEndpoint = '/attendancesimport'
export const exportAttendanceEndpoint = '/attendancesexport'
export const weeklyTimeTableSubjectsEndpoint = '/week_time_table'
export const addSubjectsOrTimeEndpoit = '/week_time_table'
export const getWeekFilterUsingAllEndpoint = '/week_all'
export const publishWeeklyTimeTableEndpoint = '/week_time_table_publish'
export const updateDataSubjectIdEndpoint = '/week_time_table'
export const deleteRowWeeklyTimeTableEndpoint = '/week_time_table'
export const addDishInDishesEndpoint = '/lunch'
export const showDishListInDishesEndpoint = '/lunch'
export const lunchMenuSearchEndpoit = '/lunch_menu_search'
export const lunchMenuGetByDishEndpoit = '/lunch_menu'
export const lunchMenuAllEndpoit = '/lunch_menu_all'
export const lunchMenuDishDetailsEndpoit = '/lunch'
export const removeLunchImageEndpoint = '/lunch_image'
export const holidayAllEndpoint = '/holiday'
export const holidayByMonthEndpoint = '/holiday_search'
export const miscellaneousEndpoint = '/miscellaneous'
export const classStudentListEndpoint = '/classStudentList'
export const diaryMultipleEndpoint = '/dairy_many'
export const diaryEndpoint = '/dairy'
export const diaryAcknowledgementEndpoint = '/dairy_ac'
export const schoolGradeEndpoint = '/school_grades'
export const libraryEndpoint = '/library'
export const libraryBookEndpoint = '/book'
export const postPaymentEndpoint = '/notifications'
export const putPaymentEndpoint = '/create-payment-notification'
export const paymentEndpoint = '/payments'
export const commentsEndpoint = '/payment-comments'
export const postCommentEndpoint = '/payment-comment'
export const unpaidUsersEndpoint = '/payment-pending'
export const profileEndpoint = '/dairy'
export const paidUsersEndpoint = '/paymentUserList'
export const payEndpoint = '/pay'
export const reportEndpoint = '/schoolReport'
export const downloadReportEndpoint = '/schoolReportImport'
export const chatRoomEndpoint = '/createChatRoom'
export const chatEndpoint = '/chats'
