import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { makeStyles, Typography, Input, ListItem, IconButton, Menu, MenuItem } from '@material-ui/core'
import UserIcon from '../../assets/images/chat/User.svg'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import ChatIcon from '@material-ui/icons/Chat'
import { Lang } from '../../Constants/Languages/English'
import Chat from './Chat'
import Contacts from './Contacts'

const useStyles = makeStyles((theme) => ({
	cursorPointer: {
		cursor: 'pointer',
	},
	root: {
		marginLeft: '13px',
		marginTop: '15px',
		marginRight: '13px',
	},
	headingContainer: {
		display: 'flex',
		alignItems: 'center',
		'& div': {
			margin: '3px',
		},
		width: '100%',
	},
	headingText: {
		fontWeight: 400,
		fontSize: '1rem',
		fontStyle: 'normal',
		color: theme.palette.common.bastille,
		width: '55%',
	},
	addTaskIcon: {
		cursor: 'pointer',
		bottom: 0,
	},
	newGroup: {
		verticalAlign: 'middle',
		justifyContent: 'center',
		color: theme.palette.primary.main,
	},
}))

const ChatIndex = (props) => {
	const {
		contacts,
		chatUsers,
	} = props

	const classes = useStyles()
	const [newGroup, setNewGroup] = useState(false)	
	const [newContact, setNewContact] = useState(false)	
	const [anchorEl, setAnchorEl] = useState(null)

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget)
	}

	const handleClose = async (event) => {
		setAnchorEl(null);
	}

	return (
		<>
			<div className={classes.root}>
				{!newGroup && (
					<div className={classes.headingContainer}>
						<div>
							<img src={UserIcon} alt='User' />
						</div>
						<Typography className={classes.headingText}>
							{' '}
							{Lang.HOME.CHATS}
						</Typography>
						<IconButton
							onClick={() => setNewContact(!newContact)}
						>
							<ChatIcon color='primary'/>
						</IconButton>
						
						<div>							
							<IconButton
								aria-label='more'
								aria-controls='long-menu'
								aria-haspopup='true'
								onClick={handleClick}
								classes={{ root: classes.iconButtonRoot }}
							>
								<MoreVertIcon color='primary'/>
							</IconButton>
							<Menu
								id='long-menu'
								anchorEl={anchorEl}
								classes={{ paper: classes.menuContainer, list: classes.menuList }}
								elevation={0}
								keepMounted
								anchorOrigin={{
									vertical: 'top',
									horizontal: 'right',
								}}
								transformOrigin={{
									vertical: 'top',
									horizontal: 'right',
								}}
								open={Boolean(anchorEl)}
								onClose={handleClose}
							>
								<MenuItem
									onClick={() => setNewGroup(true)}
									classes={{ root: classes.menuItemRoot }}
									disableGutters
									className={`${classes.menuItem} ${classes.menuTopItemMargin} `}
									value={'archive'}
								>
									<div>
										<Typography variant='body2'>{Lang.HOME.NEW_GROUP}</Typography>
									</div>
								</MenuItem>							
							</Menu>
						</div>
					</div>
				)}

				{
					!newGroup ? 
						<Chat
							userInfo={props.userInfo}
							chatUsers={chatUsers}
							contacts={contacts}
							newContact={newContact}
							onSetNewContact={setNewContact}
						/>
					:  	
						<Contacts 
							current_user_id={props.userInfo.id}
							contacts={contacts}	
							setGroup={setNewGroup}			
						/>
				}
			</div>
		</>
	)
}

const mapStateToProps = ({Chat}) => {
	const { contacts,chatUsers } = Chat;
	return {
	  	contacts: contacts,
	  	chatUsers: chatUsers,
	}
}

export default connect(mapStateToProps)(ChatIndex)
