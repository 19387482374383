export default {
    GET_CONTACTS: {
        START: 'chat.get_contacts:start',
        SUCCESS: 'chat.get_contacts:success',
        FAIL: 'chat.get_contacts:fail',
    },
    CREATE_CHATROOM: {
        START: 'chat.create_chatroom:start',
        SUCCESS: 'chat.create_chatroom:success',
        FAIL: 'chat.create_chatroom:fail',
    },
    GET_CHAT_USERS: {
        START: 'chat.get_chat_users:start',
        SUCCESS: 'chat.get_chat_users:success',
        FAIL: 'chat.get_chat_users:fail',
    },
    SEND_CHAT: {
        START: 'chat.send_chat:start',
        SUCCESS: 'chat.send_chat:success',
        FAIL: 'chat.send_chat:fail',
    },
    SET_CHAT_USER: 'chat.set_chat_user',
    UPDATE_CHAT: 'chat.update_chat',
}