export const AUTH_USER = 'AUTH_USER'
export const AUTH_CHANGE_PROFILE = 'AUTH_CHANGE_PROFILE'
export const AUTH_START = 'AUTH_START'
export const AUTH_SUCCESS = 'AUTH_SUCCESS'
export const AUTH_FAIL = 'AUTH_FAIL'
export const AUTH_INITIATE_LOGOUT = 'AUTH_INITIATE_LOGOUT'
export const AUTH_LOGOUT = 'AUTH_LOGOUT'
export const AUTH_CHECK_STATE = 'AUTH_CHECK_STATE'
export const AUTH_CHECK_TIMEOUT = 'AUTH_CHECK_TIMEOUT'

export const AUTH_INITIATE_ROLE_SELECTION = 'AUTH_INITIATE_ROLE_SELECTION'
export const AUTH_ROLE_SELECTION = 'AUTH_ROLE_SELECTION'
export const AUTH_ROLE_SELECTION_SUCCESS = 'AUTH_ROLE_SELECTION_SUCCESS'
