import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(() => ({
	sectionContainer: {
		width: '100%',
		padding: '20px',
		boxSizing: 'border-box',
		marginBottom: '65px',
		display: 'grid',
	},
}))

export default useStyles;